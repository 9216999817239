import React from 'react';
import s from './EmptyGallery.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import classNames from 'classnames';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/withTranslations';

export interface EmptyGalleryProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

@withGlobalProps
@withTranslations()
export class EmptyGallery extends React.Component<EmptyGalleryProps> {
  public render() {
    const {hasFilters} = this.props;
    return (
      <header data-hook="empty-gallery" className={classNames(s.emptyGallery, {[s.filterEmptyState]: hasFilters})}>
        <h2 className={s.titleSub}>
          {hasFilters ? this.props.t('noProductsFilteredMessageText') : this.props.t('noProductsMessageText')}
        </h2>
      </header>
    );
  }
}
